<template>
  <div class="import-file">
    <el-tooltip content="导入数据" placement="top" effect="light">
      <el-upload
        ref="upload"
        :headers="header"
        :action="uploadUrl"
        :data="uploadData"
        :on-error="handleError"
        :on-success="handleSuccess"
        :before-upload="beforerUpload"
        :show-file-list="false"
      >
        <el-button
          class="cancel"
          size="mini"
          icon="el-icon-upload2"
          circle></el-button
        >
      </el-upload>
    </el-tooltip>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { message } from "ant-design-vue";
export default {
  name:"ImportFile",
  props:{uploadData: {
      type: Object,
      default: () => {} // 上传时附带的额外参数
    }
},
  data(){
    return{
      uploadUrl: '/api/system/upload/file',  // 请求地址,
      header: { Authorization: Cookies.get('Authorization') }, // 请求头部
    }
  },
  methods:{
    // 文件上传前的钩子
    beforerUpload(file) {
      const isXslx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        // 设置文件大小
        // const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isXslx) {
        message.error("请上传xlsx格式文件")
      }
      // 限制文件大小
      // if (!isLt2M) {
      //   message.error("上传文件大小不能超过 2MB!")
      // }
      // return isLt2M && isXslx;
      return isXslx;
    },
 
    // 上传失败钩子
    handleError(res) {
      message.error(res.message);
    },
 
    // 成功上传钩子
    handleSuccess(res) {
      message.success(res.message);
      this.$emit('getStringUrl',res.data.url);
    }
  }
}
</script>
<style lang="scss" scoped>
</style>