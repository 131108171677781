import { Request } from '@/http/request'

// 用户小号列表
export function getNumberList(parameter: any){
  return Request.axiosInstance.post('/open/user-number/list', parameter)
}

// 用户小号增添
export function numberAdd(parameter: any){
  return Request.axiosInstance.post('/open/user-number/add', parameter)
}

// 用户小号修改
export function numberEdit(parameter: any){
  return Request.axiosInstance.post('/open/user-number/edit', parameter)
}

// 用户小号删除
export function numberDelete(parameter: any){
  return Request.axiosInstance.post('/open/user-number/delete', parameter)
}

// 用户购买小号
export function numberShop(parameter: any){
  return Request.axiosInstance.post('/open/user-number/automatic-distribution', parameter)
}

// 用户小号批量分配
export function UserBranchNum(parameter: any){
  return Request.axiosInstance.post('/open/user-number/batch-distribution', parameter)
}

// 用户小号批量分配
export function UserNumberRecover(parameter: any){
  return Request.axiosInstance.post('/open/user-number/number-recover', parameter)
}

export default { getNumberList,numberAdd,numberEdit,numberDelete,numberShop,UserBranchNum,UserNumberRecover }